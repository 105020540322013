import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { fadeIn } from '../../../helpers/variants';

const Banner = ({ title, text, btnText, bc = '' }) => {
    return (
        <div className={`banner ${bc}`}>
            <div className="container">
                <motion.h2 
                    variants={fadeIn('right', 0.1)}
                    initial='hidden'
                    whileInView={'show'}
                    viewport={{ once: true, amount: 0.3 }}
                    className="banner-title"
                >
                    {title}
                </motion.h2>
                <motion.p 
                    variants={fadeIn('right', 0.3)}
                    initial='hidden'
                    whileInView={'show'}
                    viewport={{ once: true, amount: 0.3 }}
                    className="description"
                >
                    {text}
                </motion.p>
                <motion.button
                    variants={fadeIn('', 0.5)}
                    initial='hidden'
                    whileInView={'show'}
                    viewport={{ once: true, amount: 0.3 }}
                >
                    {btnText} {">"}
                </motion.button>
            </div>
        </div>
    );
};

Banner.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.any.isRequired,
    btnText: PropTypes.string.isRequired,
    bc: PropTypes.string.isRequired,
};

export default Banner;