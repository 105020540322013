import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// ? Rutas privadas, a las cuales se accede solo si esta logueado
const PrivateRoutes = ({ children }) => {
    const { userData } = useSelector(state => state.auth);

    return !!userData
        ? children
        : <Navigate to='/para-medicos/iniciar-sesion' />
};

export default PrivateRoutes;