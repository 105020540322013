import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProfessionalRoutes from "./ProfessionalRoutes";
import PatientsRoutes from "./PatientsRoutes";
import Home from "../components/screens/Home";
import Footer from "../components/layouts/footer/Footer";

const AppRouter = () => {
    return (
        // <BrowserRouter basename='/'>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />

                {/* RUTAS PARA PACIENTES*/}
                <Route 
                    path='/para-todos/*'
                    element={<PatientsRoutes />}
                />

                {/* RUTAS PARA MEDICOS */}
                <Route
                    path="/para-medicos/*"
                    element={<ProfessionalRoutes />}
                />

                {/* 404 */}
                <Route path="*" element={<h1>No existe</h1>} />
            </Routes>

            <Footer />
        </BrowserRouter>
    );
};

export default AppRouter;
