import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const BreadCrumbs = ({ homeUrl, section, type, typeUrl = null, media = null }) => {
    return (
        <div className="flex gap-[10px] breadcrumbs pb-[10px]">
            <Link to={homeUrl}>
                <img
                    src="/images/icon-house.png"
                    alt="home"
                    width={22}
                    height={24}
                />
            </Link>
            <p>{">"}</p>
            <p>{section}</p>
            {
                type && <p>{">"}</p>
            }
            {
                media
                    ? 
                    <>
                        <Link to={typeUrl}>
                            <p style={{ textTransform: 'capitalize' }}>{type}</p>
                        </Link>
                        <p>{">"}</p>
                        <p>{media}</p>
                    </>
                    :
                    <p style={{ textTransform: 'capitalize' }}>{type}</p>
            }
        </div>
    );
};

BreadCrumbs.propTypes = {
    homeUrl: PropTypes.string.isRequired,
    section: PropTypes.string.isRequired,
    type: PropTypes.string,
    typeUrl: PropTypes.string,
    media: PropTypes.string,
};

export default BreadCrumbs;