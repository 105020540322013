export const routes = (sections, current) => {
    const routes = [];

    sections.forEach(section => {
        routes.push({
            name: section.slug,
            title: section.name,
            description: section.description,
            items: section.items.map(item => ({
                name: item.name,
                path: `/${current}/${section.slug}/${item.slug}`
            }))
        });
    });

    return routes;
}
