import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import { userStartCheckingToken } from '../actions/auth';
import Login from '../components/screens/profesionales/login/Login';
import Register from '../components/screens/profesionales/registro/Register';
import ChangePasswordScreen from '../components/screens/profesionales/changePassword/ChangePasswordScreen';
import Principal from '../components/screens/common/home/Home';
import SectionScreen from '../components/screens/common/SectionScreen/SectionScreen';
import MediaScreen from '../components/screens/common/mediaScreen/MediaScreen';
import { sectionsStartLoading } from '../actions/ui';
import SavedMediasScreen from '../components/screens/profesionales/savedMedias/SavedMediasScreen';

const ProfessionalRoutes = () => {

    const dispatch = useDispatch();

    const { pathname } = useLocation();

    const { loaded } = useSelector(state => state.auth);

    const [fetched, setFetched] = useState(false);

    // ? Obtener secciones de pacientes
    const getSectionsData = useCallback(() => {
        if(!pathname.includes('iniciar-sesion') && !pathname.includes('registrarse') && !fetched) {
            dispatch(sectionsStartLoading({ professional: true }));
            setFetched(true);
        }
    }, [dispatch, pathname, fetched]);

    useEffect(() => {
        dispatch(userStartCheckingToken());
        getSectionsData();
    }, [dispatch, getSectionsData]);

    return loaded
        ?
        <Routes>
            <Route
                path='iniciar-sesion'
                element={
                    <PublicRoutes>
                        <Login />
                    </PublicRoutes>
                }
            />
            <Route
                path='registrarse'
                element={
                    <PublicRoutes>
                        <Register />
                    </PublicRoutes>
                }
            />
            <Route
                path='cambiar-contrasena/:access'
                element={
                    <PublicRoutes>
                        <ChangePasswordScreen />
                    </PublicRoutes>
                }
            />
            <Route
                path='*'
                element={
                    <PrivateRoutes>
                        <Routes>
                            <Route path='sobre-sospechar-no-falla' element={<Principal />} />
                            <Route path=':section/:type' element={<SectionScreen />} />
                            <Route path=':section/:type/:media' element={<MediaScreen />} />
                            <Route path='materiales-guardados' element={<SavedMediasScreen />} />
                            <Route path='*' element={<h1>No existe</h1>} />
                        </Routes>
                    </PrivateRoutes>
                }
            />
        </Routes>
        :
        <div className='loading-screen'>
            <p>Cargando...</p>
        </div>
};

export default ProfessionalRoutes;