import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../../../hooks/UseForm';
import { Link, useNavigate } from 'react-router-dom';
import { userStartRegister } from '../../../../actions/auth';
import { professionsStartLoading, specialtiesStartLoading, uiClearMessages } from '../../../../actions/ui';
import { motion } from 'framer-motion';
import { fadeIn } from '../../../../helpers/variants';

import "./Register.styles.scss";

const initialForm = {
    name: '',
    last_name: '',
    professional_type: '',
    especialist_type: '',
    card: '',
    institution: '',
    country: '',
    city: '',
    email: '',
    phone: '',
    password: '',
    re_password: ''
};

const Register = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { professions, specialties, registerMessage, registerLoading } = useSelector(state => state.ui);

    const [errorMessage, setErrorMessage] = useState('');
    const [formValues, handleInputChange] = useForm(initialForm);
    const {
        name,
        last_name,
        professional_type,
        especialist_type,
        card,
        institution,
        country,
        city,
        email,
        phone,
        password,
        re_password
    } = formValues;


    // ? Obtener tipos de profesionales y especialidad
    const getProfessionalTypes = useCallback(() => {
        if (professions.length === 0) dispatch(professionsStartLoading());
    }, [dispatch, professions.length]);

    const getSpecialtiesTypes = useCallback(() => {
        if (specialties.length === 0) dispatch(specialtiesStartLoading());
    }, [dispatch, specialties.length]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(uiClearMessages());
        setErrorMessage('');

        const { re_password, ...rest } = formValues;

        // * Verificar que las contraeñas coincidan
        if (password !== re_password) {
            setErrorMessage('Las contraseñas no coinciden');
            return;
        }

        dispatch(userStartRegister({ userData: rest }));
    };

    useEffect(() => {
        dispatch(uiClearMessages());
        getProfessionalTypes();
        getSpecialtiesTypes();
    }, [dispatch, getProfessionalTypes, getSpecialtiesTypes]);

    return (
        <div className="register-container">
            <div className="bg-blue">
                <div
                    className="container"
                    style={{
                        width: "100%",
                        position: "relative",
                        height: "100%",
                    }}
                >
                    <header className="header">
                        <img
                            src="/images/logo.png"
                            alt="Sospechar no falla logo"
                            className="logo cursor-pointer"
                            onClick={() => navigate('/')}
                        />
                        <div className="col-md-6 d-flex align-items-center justify-content-end text-right m-0 p-0 logos-res">
                            <p className="m-0 mr-2 siguenos text-white">
                                Siguenos:
                            </p>
                            <a href="https://www.facebook.com/sospecharnofalla" target="_blank" rel="noreferrer">
                                <img
                                    src="/images/fece-b.png"
                                    className="img-fluid mr-2"
                                    alt="Facebook"
                                />
                            </a>
                            <a href="https://www.instagram.com/sospechar_nofalla/" target="_blank" rel="noreferrer">
                                <img
                                    src="/images/insta-b.png"
                                    className="img-fluid mr-2"
                                    alt="Instagram"
                                />
                            </a>
                            <a href="https://www.instagram.com/sospechar_nofalla/" target="_blank" rel="noreferrer">
                                <img
                                    src="/images/tik-b.png"
                                    className="img-fluid mr-2"
                                    alt="Tik tok"
                                />
                            </a>
                        </div>
                    </header>

                    {/** cartas */}
                    <motion.div
                        variants={fadeIn('up', 0.1)}
                        initial='hidden'
                        whileInView={'show'}
                        viewport={{ once: true, amount: 0.3 }}
                        className="container d-flex align-items-center mt-5 p-3"
                        style={{ backgroundColor: "white" }}
                    >
                        <div className="row m-0 p-0 align-items-center">
                            <div className=" col-12 col-md-8  m-0">
                                <h3 className="title-registro">Registro</h3>
                                <form className="" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name='name'
                                                    value={name}
                                                    onChange={handleInputChange}
                                                    pattern="[a-zA-Z ]{2,254}"
                                                    autoComplete='off'
                                                    className="form-control "
                                                    aria-describedby="emailHelp"
                                                    placeholder="Nombres*"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name='last_name'
                                                    value={last_name}
                                                    onChange={handleInputChange}
                                                    pattern="[a-zA-Z ]{2,254}"
                                                    autoComplete='off'
                                                    className="form-control "
                                                    aria-describedby="emailHelp"
                                                    placeholder="Apellidos*"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <select
                                                    name='professional_type'
                                                    value={professional_type}
                                                    onChange={handleInputChange}
                                                    className="form-control "
                                                    aria-describedby="emailHelp"
                                                    required
                                                >
                                                    <option value=''>Tipo de prefesional*</option>
                                                    {
                                                        professions.map(profession => (
                                                            <option
                                                                key={profession.id}
                                                                value={profession.id}
                                                            >
                                                                {profession.name}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <select
                                                    name='especialist_type'
                                                    value={especialist_type}
                                                    onChange={handleInputChange}
                                                    className="form-control "
                                                    aria-describedby="emailHelp"
                                                    required
                                                >
                                                    <option value=''>Tipo de especialidad*</option>
                                                    {
                                                        specialties.map(specialty => (
                                                            <option
                                                                key={specialty.id}
                                                                value={specialty.id}
                                                            >
                                                                {specialty.name}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name='card'
                                                    value={card}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                    className="form-control "
                                                    aria-describedby="emailHelp"
                                                    placeholder="Tarjeta profesional*"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name='institution'
                                                    value={institution}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                    className="form-control "
                                                    aria-describedby="emailHelp"
                                                    placeholder="Institución donde labora*"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name='country'
                                                    value={country}
                                                    onChange={handleInputChange}
                                                    pattern="[a-zA-Z ]{2,254}"
                                                    autoComplete='off'
                                                    className="form-control "
                                                    aria-describedby="emailHelp"
                                                    placeholder="País*"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name='city'
                                                    value={city}
                                                    onChange={handleInputChange}
                                                    pattern="[a-zA-Z ]{2,254}"
                                                    autoComplete='off'
                                                    className="form-control "
                                                    aria-describedby="emailHelp"
                                                    placeholder="Ciudad*"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    name='email'
                                                    value={email}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                    className="form-control "
                                                    aria-describedby="emailHelp"
                                                    placeholder="Correo electrónico*"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name='phone'
                                                    value={phone}
                                                    onChange={handleInputChange}
                                                    autoComplete='off'
                                                    className="form-control "
                                                    aria-describedby="emailHelp"
                                                    placeholder="Teléfono*"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="password"
                                                    name='password'
                                                    value={password}
                                                    onChange={handleInputChange}
                                                    className="form-control "
                                                    aria-describedby="emailHelp"
                                                    placeholder="Contraseña*"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="password"
                                                    name='re_password'
                                                    value={re_password}
                                                    onChange={handleInputChange}
                                                    className="form-control "
                                                    aria-describedby="emailHelp"
                                                    placeholder="Repetir contraseña*"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-12 col-md-4 ">
                                <div className=" mt-5 text ">
                                    <h3 className="bienvenidos">Bienvenido</h3>
                                    <p className="eres">
                                        Si ya eres un usuario registrado,
                                        <br />
                                        <Link to='/para-medicos/iniciar-sesion' className="eres">
                                            haz click aquí
                                        </Link>
                                    </p>
                                </div>
                                <div className="">
                                    <button
                                        type="button"
                                        className={registerLoading ? 'btn disabled' : 'btn entrar1'}
                                        onClick={handleSubmit}
                                    >
                                        {
                                            registerLoading
                                            ? 'Enviando...'
                                            :
                                            <>
                                                Enviar{" "}
                                                <img
                                                    src="/images/flecha-white2.png"
                                                    className="d-inline-block"
                                                    alt="flecha logo"
                                                />   
                                            </>
                                        }
                                    </button>
                                </div>
                                <div className=" mt-3">
                                    <p className="autorizo">
                                        *Camplos obligatorios
                                    </p>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="defaultCheck1"
                                            required
                                        />
                                        <label
                                            className="form-check-label t-autorizo p-0"
                                            htmlFor="defaultCheck1"
                                        >
                                            Autorizo expresamente a Boehringer
                                            Ingelheim S.A., en calidad de
                                            responsable del tratamiento, paraque
                                            por sí mismo o en asocio con
                                            terceros, realice el tratamiento de
                                            los datos personales aquí
                                            suministrados con las finalidades
                                            precisadas en el siguiente aviso de
                                            privacidad. Para más información
                                            consulte nuestra “Política de
                                            Tratamiento de Datos Personales”
                                        </label>
                                    </div>

                                    {
                                        registerMessage &&
                                        <p
                                            className={
                                                registerMessage.type === 'error'
                                                ? "error-message"
                                                : "success-message"
                                            }
                                        >
                                                {registerMessage.message}*
                                        </p>
                                    }
                                    {
                                        errorMessage && 
                                        <p className="error-message">
                                            {errorMessage}*
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </motion.div>

                </div>
            </div>
        </div>
    );
};

export default Register;
