import { types } from "../types/types";
import { tokenFetch, freeFetch } from "../helpers/fetch";
import { 
    uiLastMediasFinishLoading, 
    uiLastMediasStartLoading, 
    uiLastSocialMediasFinishLoading, 
    uiLastSocialMediasStartLoading, 
    uiMediaDataFinishLoading, 
    uiMediaDataStartLoading, 
    uiMediasFinishLoading, 
    uiMediasHistoryFinishLoading, 
    uiMediasHistoryStartLoading, 
    uiMediasSavedFinishLoading, 
    uiMediasSavedStartLoading, 
    uiMediasStartLoading, 
    uiRelatedMediasFinishLoading,
    uiRelatedMediasStartLoading
} from "./ui";

// ? Obtener datos de una media
export const mediaDataStartLoading = ({ media, section, type, professional }) => {
    return async (dispatch) => {
        try {
            dispatch(uiMediaDataStartLoading());
            const resp = professional
                ? await tokenFetch(`Medias/getProfessionalMedia?media=${media}&section=${section}&type=${type}`)
                : await freeFetch(`Medias/getPatientsMedia?media=${media}&section=${section}&type=${type}`);

            const data = await resp.json();

            if(data.status) {
                if(professional) {
                    dispatch(mediaDataLoaded({...data.media, saved: data.saved, liked: data.liked}));
                    
                    // * Guardar multimedia en el historial
                    dispatch(saveHistoryStartLoading({ 
                        item_id: data.media.id, 
                        section_id: data.media.section_id, 
                        type_id: data.media.type_id 
                    }));
                } else {
                    dispatch(mediaDataLoaded(data.media));
                }

                // * Cargar multimedias relacionadas
                dispatch(relatedMediasStartLoading({ id: data.media.id,  keywords: data.media.key_words, professional }));
            } else {
                dispatch(mediaDataLoaded(null));
            }
            
            dispatch(uiMediaDataFinishLoading());
        } catch(error) {
            console.log(error);
        }
    }
};

const mediaDataLoaded = (media) => ({
    type: types.mediaDataLoaded,
    payload: media
});

// ? Obtener ultimas 3 medias
export const lastMediasStartLoading = ({ professional }) => {
    return async (dispatch) => {
        try {
            dispatch(uiLastMediasStartLoading());
            const resp = professional
                ? await tokenFetch('Medias/getLatestProfessionalMedia')
                : await freeFetch('Medias/getLatestPatientlMedia');

            const data = await resp.json();

            if(data.status) {
                dispatch(lastMediasLoaded(data.medias));
            }
            
            dispatch(uiLastMediasFinishLoading());
        } catch(error) {
            console.log(error);
        }
    }
};

const lastMediasLoaded = (medias) => ({
    type: types.lastmediasLoaded,
    payload: medias
});

// ? Obtener ultimos 3 post de redes sociales (desde base de datos de la API)
export const lastSocialMediasStartLoading = () => {
    return async (dispatch) => {
        try {
            dispatch(uiLastSocialMediasStartLoading());
            const resp = await freeFetch('Medias/getLastMediasFromSocialMedia');

            const data = await resp.json();

            if(data.status) {
                dispatch(lastSocialMediasLoaded(data.medias));
            }
            
            dispatch(uiLastSocialMediasFinishLoading());
        } catch(error) {
            console.log(error);
        }
    }
};

const lastSocialMediasLoaded = (medias) => ({
    type: types.lastSocialmediasLoaded,
    payload: medias
});

// ? Obtener medias de una seccion y tipo
export const allMediasStartLoading = ({ section, type, professional }) => {
    return async (dispatch) => {
        try {
            dispatch(uiMediasStartLoading());
            const resp = professional
                ? await tokenFetch(`Medias/getProfessionalMedias?section=${section}&type=${type}`)
                : await freeFetch(`Medias/getPatientsMedias?section=${section}&type=${type}`);

            const data = await resp.json();

            if(data.status) {
                dispatch(mediasLoaded(data.medias));

                // * Obtener historial de medias vistas
                if(professional) dispatch(mediasHistoryStartLoading({ section, type, medias: data.medias }));
            } else {
                dispatch(mediasLoaded([]));
                dispatch(mediasHistoryLoaded([]));
            }
            
            dispatch(uiMediasFinishLoading());
        } catch(error) {
            console.log(error);
        }
    }
};

const mediasLoaded = (medias) => ({
    type: types.mediasLoaded,
    payload: medias
});

// ? Obtener historial de medias vistas en cada seccion
const mediasHistoryStartLoading = ({ section, type, medias }) => {
    return async (dispatch) => {
        try {
            dispatch(uiMediasHistoryStartLoading());
            const resp = await tokenFetch(`Medias/getMediaHistory?section=${section}&type=${type}`);

            const data = await resp.json();

            if(data.status) {
                //  * Poner las medias del historial en base a los id's de medias vistas obtenidos en el fetch
                const historyAux = [];
                data.medias.forEach(item => {
                    historyAux.push(
                        medias.filter(media => media.id === item.multimedia_id)[0]
                    );
                });

                dispatch(mediasHistoryLoaded(historyAux));
            } else {
                dispatch(mediasHistoryLoaded([]));
            }
            
            dispatch(uiMediasHistoryFinishLoading());
        } catch(error) {
            console.log(error);
        }
    }
};

const mediasHistoryLoaded = (medias) => ({
    type: types.mediasHistoryLoaded,
    payload: medias
});

//  ? Guardar historial de materiales vistos
const saveHistoryStartLoading = ({ item_id, type_id, section_id }) => {
    return async (dispatch) => {
        try {
            const resp = await tokenFetch('Medias/setMediaHistory', 'POST', { item_id, type_id, section_id });
            const data = await resp.json();

            console.log(data);
        } catch(error) {
            console.log(error);
        }
    }
}

// ? Obtener materiales realcionados a una media
export const relatedMediasStartLoading = ({ id, keywords, professional }) => {
    return async (dispatch) => {
        try {
            dispatch(uiRelatedMediasStartLoading());

            const resp = professional
                ? await tokenFetch(`Medias/getRelatedProfessionalMedias?keywords=${keywords}`)
                : await freeFetch(`Medias/getRelatedPatientsMedias?keywords=${keywords}`);

            const data = await resp.json();

            if(data.status) {
                dispatch(relatedMediasLoaded(data.medias.filter(media => media.id !== id)));
            } else {
                dispatch(relatedMediasLoaded([]));
            }
            
            dispatch(uiRelatedMediasFinishLoading());
        } catch(error) {
            console.log(error);
        }
    }
}

const relatedMediasLoaded = (medias) => ({
    type: types.relatedmediasLoaded,
    payload: medias
});

// ? Dar like a un material
export const likeStartLoading = ({ id }) => {
    return async (dispatch) => {
        try {
            const resp = await tokenFetch('Medias/setLikedItems', 'POST', { id });
            const data = await resp.json();

            if(data.status) {
                dispatch(mediaUpdated({ liked: true }));
            } else {
                console.log(data);
            }
        } catch(error) {
            console.log(error);
        }
    }
};

// ? Eliminar like de un material
export const unlikeStartLoading = ({ id }) => {
    return async (dispatch) => {
        try {
            const resp = await tokenFetch(`Medias/deleteLikedItem?id=${id}`, 'DELETE');
            const data = await resp.json();

            if(data.status) {
                dispatch(mediaUpdated({ liked: false }));
            } else {
                console.log(data);
            }
        } catch(error) {
            console.log(error);
        }
    }
};

// ? Guardar material
export const saveMediaStartLoading = ({ id, areSaved, mediaToSave }) => {
    return async (dispatch) => {
        try {
            const resp = await tokenFetch('Medias/setSavedItems', 'POST', { id });
            const data = await resp.json();

            if(data.status) {
                dispatch(mediaUpdated({ saved: true }));

                // Si ya hay medias guardadas cargadas agregar nueva
                if(areSaved) dispatch(savedMediasAddNew(mediaToSave));
            } else {
                console.log(data);
            }
        } catch(error) {
            console.log(error);
        }
    }
};

// ? Eliminar material guardado
export const deleteSavedMediaStartLoading = ({ id, areSaved }) => {
    return async (dispatch) => {
        try {
            const resp = await tokenFetch(`Medias/deleteSavedItem?id=${id}`, 'DELETE');
            const data = await resp.json();

            if(data.status) {
                dispatch(mediaUpdated({ saved: false }));

                // Si hay medias guardadas cargadas, eliminar la borrada del estado
                if(areSaved) dispatch(savedMediasDelete(id));
            } else {
                console.log(data);
            }
        } catch(error) {
            console.log(error);
        }
    }
};

const mediaUpdated = (data) => ({
    type: types.mediaUpdated,
    payload: data
});

const savedMediasAddNew = (media) => ({
    type: types.mediasSavedAddNew,
    payload: media
});

const savedMediasDelete = (id) => ({
    type: types.mediasSavedDelete,
    payload: id
});

// ? Obtener materiales guardados
export const savedMediasStartLoading = () => {
    return async (dispatch) => {
        try {
            dispatch(uiMediasSavedStartLoading());
            const resp = await tokenFetch('Medias/getSavedItems');

            const data = await resp.json();

            if(data.status) {
                dispatch(savedMediasLoaded(data.medias));
            }
            
            dispatch(uiMediasSavedFinishLoading());
        } catch(error) {
            console.log(error);
        }
    }
};

const savedMediasLoaded = (medias) => ({
    type: types.mediasSavedLoaded,
    payload: medias
});