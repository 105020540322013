import React from 'react';
import PropTypes from 'prop-types';

const BannerCard = ({ thumb, title, alt }) => {
    return (
        <div className="col-md-6 center-card">
            <div className="card">
                <div className="card-image">
                    <img
                        src={thumb}
                        alt={alt}
                        width={164}
                        height={165}
                    />
                </div>
                <div className="card-description">
                    <h3>
                        {title}
                    </h3>
                    <p>Leer más {">"}</p>
                </div>
            </div>
        </div>
    );
};

BannerCard.propTypes = {
    thumb: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};

export default BannerCard;