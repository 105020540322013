import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { fadeIn } from '../../../helpers/variants';
import { useLocation, useNavigate } from 'react-router-dom';

const MediaCard = ({ thumb, name, description, url = '', external = false }) => {

    const { pathname } = useLocation();
    const navigate = useNavigate();

    const handleNavigate = () => {
        if (external) {
            window.open(url);
        } else {
            navigate(`/${pathname.split('/')[1]}/${url}`);
        }
    }; 

    return (
        <motion.div 
            variants={fadeIn('up', 0.1)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: true, amount: 0.3 }}
            className="col-md-4 items-more"
            onClick={handleNavigate}
        >
            <div className="card media-card">
                <div className="card-image">
                    <img src={thumb} alt={name} />
                </div>
                <div className="card-description">
                    <div>
                        <h3>
                            {
                                name?.length > 35
                                    ? name.substring(0, 35) + '...'
                                    : name
                            }
                        </h3>
                        <p>
                            {   
                                description?.length > 50
                                ? description?.substring(0, 50) + '...' 
                                : description
                            }
                        </p>
                    </div>
                    <div>
                        <h4>Leer más {">"}</h4>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

MediaCard.propTypes = {
    thumb: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string,
    external: PropTypes.bool
};

export default MediaCard;