import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routes } from "./menu-items";
import { uiPageFinishLoading, uiPageStartLoading } from "../../../../actions/ui";
import Drawer from "./components/drawer/Drawer";
import ProfileMenu from "./ProfileMenu";

import "./Header.styles.scss";
import EditUserModal from "../../editUserModal/EditUserModal";
import EditPicModal from "../../editPicModal/EditPicModal";

const Menu = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const currentSection = pathname.split('/')[1];
  
  const { sections, sectionsLoading, updateModalOpen, picModalOpen } = useSelector(state => state.ui);

  const [openModal, setOpenModal] = useState(false);
  const [subMenu, setSubmenu] = useState({});
  const [menuProfile, setMenuProfile] = useState(false);


  const isRoute = (path) => {
    return pathname.includes(path);
  };

  const getObject = (type) => {
    const route = routes(sections, currentSection).find((item) => item.name === type);
    if (subMenu.name === type) {
      return { open: false };
    } else {
      return { open: true, ...route };
    }
  };

  const handleMenuItemAction = (slug, name) => {
    if(name === 'Blogs') {
      navigate(`/${currentSection}/blogs/blogs`);
    } else {
      setSubmenu(getObject(slug));
    }
  };

  const handleLoadPage = () => {
    setSubmenu({ open: false });

    dispatch(uiPageStartLoading());

    setTimeout(() => dispatch(uiPageFinishLoading()), 100);
  };

  return (
    <>
      <Drawer open={openModal} onClose={() => setOpenModal(false)} />
      <div className="header-container">
        <div className="bg-blue relative">
          <div className="container">
            <header className="header">
              <img
                src="/images/logo-gris.png"
                alt="Logo sospechar no falla"
                className="logo cursor-pointer"
                onClick={() => navigate('/')}
              />
              
              {
                sectionsLoading 
                  ? <div className="w-full h-[50%] skeleton"></div>
                  : <nav className="navigation">
                      <ul className="navbar-nav align-items-center active">
                        <li
                          className={`nav-item active ${isRoute(
                            currentSection === 'para-todos'
                              ? '/para-todos/inicio'
                              : '/para-medicos/sobre-sospechar-no-falla'
                          ) ? "web" : ""
                            }`}
                        >
                          <Link
                            to={currentSection === 'para-todos'
                              ? '/para-todos/inicio'
                              : '/para-medicos/sobre-sospechar-no-falla'}
                            className="nav-link  text-center"
                          >
                            {
                              currentSection === 'para-todos' ? 'Inicio' : 'Sobre Sospechar No Falla'
                            }
                          </Link>
                        </li>

                        {
                          sections?.map(section => (
                            <li
                              key={section.id}
                              className={`nav-item active ${isRoute(`/${currentSection}/${section.slug}`) ? "web" : ""}`}
                              onClick={() => handleMenuItemAction(section.slug, section.name)}
                            >
                              <span className="nav-link flex text-center items-center gap-[10px]">
                                {section.name}
                                <span className="sr-only">(current)</span>
                                {
                                  section.name !== 'Blogs' &&
                                  <img
                                    className="w-[15px]"
                                    src="/images/flechita-abajo.png"
                                    alt="flechita"
                                  />
                                }
                              </span>
                            </li>
                          ))
                        }

                        <li className="nav-item flex items-center gap-[5px]">
                          <img
                            className="w-[15px]"
                            src="/images/flechita-abajo.png"
                            alt="flechita"
                            style={{
                              transform: " rotate(270deg)"
                            }}
                          />
                          <a
                            className="nav-link "
                            href={
                              currentSection === 'para-todos'
                                ? '/para-medicos/sobre-sospechar-no-falla'
                                : '/para-todos/inicio'
                            }
                            target='_blank'
                            rel='noreferrer'
                            style={{ fontWeight: "bold" }}
                          >
                            {
                              currentSection === 'para-todos' ? 'Ir a web para médicos' : 'Ir a web para todos'
                            }
                          </a>
                        </li>

                        {
                          currentSection === 'para-medicos' &&
                          <li 
                            className="nav-item ml-3 usericon"
                            onClick={() => setMenuProfile(!menuProfile)}
                          >
                            <div className="dropdown">
                              <button
                                className="drow"
                                type="button"
                              >
                                <img
                                  src="/images/logo-user.png"
                                  width={40}
                                  height={40}
                                  className="d-inline-block align-top"
                                  alt="Logo perfil"
                                />
                              </button>

                              { /* PROFILE MENU */ }
                              {
                                menuProfile &&
                                <ProfileMenu />
                              }
                            </div>
                          </li>
                        }
                      </ul>
                    </nav>
              }

              <img
                src="/images/icons/hamburguer.svg"
                alt="Icono menú"
                width={30}
                height={30}
                className="hamburguer"
                onClick={() => setOpenModal(true)}
              />
            </header>
          </div>

          <div className="mesas">
            <p>
              {
                currentSection === 'para-todos'
                  ? 'CONTENIDO PARA TODOS'
                  : 'CONTENIDO PARA PROFESIONALES DE LA SALUD'
              }
            </p>
          </div>

          {/* SUBMENU */}
          <div
            className="menu"
            style={{ display: subMenu.open ? "flex" : "none" }}
          >
            <div className="container">
              <div className="flex justify-between mx-0">
                <div className="description-menu">
                  <div className="description">
                    <h2 className="title">{subMenu.title}</h2>
                    <h3 className="text">{subMenu.description}</h3>
                  </div>
                </div>
                <div className="nav">
                  {subMenu.items &&
                    subMenu.items.map((item, index) => (
                      <Link 
                        key={index} 
                        className="item" 
                        to={item.path}
                        onClick={handleLoadPage}
                      >
                        {item.name} {">"}
                      </Link>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      { updateModalOpen && <EditUserModal /> }
      { picModalOpen && <EditPicModal /> }
    </>
  );
};

export default Menu;
