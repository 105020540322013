import React from 'react';
import PropTypes from 'prop-types';
import { handleCopyToClipboard } from '../../../../helpers/copyToClipboard';

const InfoModal = ({ setForgot }) => {
    return (
        <div className='info-modal'>
            <div className='info-modal-content'>
                <button
                    onClick={() => setForgot(false)}
                >
                    X
                </button>
                <p>
                    Por favor solicita tu cambio de contraseña al correo
                    <br />
                    <i onClick={() => handleCopyToClipboard('medfora.co@boehringer-ingelheim.com')}>
                        medfora.co@boehringer-ingelheim.com
                    </i>
                </p>
            </div>
        </div>
    );
};

InfoModal.propTypes = {
    setForgot: PropTypes.func.isRequired
};

export default InfoModal;
