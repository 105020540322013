import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { fadeIn } from '../../../helpers/variants';
import { useLocation, useNavigate } from 'react-router-dom';

const SimpleMediaCard = ({ thumb, name, description, url = '', external = false }) => {

    const { pathname } = useLocation();
    const navigate = useNavigate();

    const handleNavigate = () => {
        if (external) {
            window.open(url);
        } else {
            navigate(`/${pathname.split('/')[1]}/${url}`);
        }
    }; 

    return (
        <motion.div 
            variants={fadeIn('', 0.1)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className="col-md-4 media-card"
            onClick={handleNavigate}
        >
            <img src={thumb} alt={name} className='w-full' />
            <div className="p-[10px]">
                <h3 className="title">
                    {
                        name?.length > 35
                            ? name.substring(0, 35) + '...'
                            : name
                    }
                </h3>
                <p className="description">
                    {
                        description?.length > 50
                            ? description?.substring(0, 50) + '...'
                            : description
                    }
                </p>
            </div>
        </motion.div>
    );
};

SimpleMediaCard.propTypes = {
    thumb: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    external: PropTypes.bool,
    url: PropTypes.string
};

export default SimpleMediaCard;