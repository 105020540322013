import React from 'react'
import { useSelector } from 'react-redux';
import { baseApiUrl } from '../../../helpers/global';
import { Interweave } from 'interweave';

import './BlogViewer.styles.scss';

const BlogViewer = () => {

    const { mediaData } = useSelector(state => state.medias);

    return (
        <div className="detail">
            {
                mediaData?.content?.map((paragraph, index) => (
                    <div key={index}>
                        {paragraph.title && <h4 className='paragraph-sub'>{paragraph.title}</h4>}
                        <Interweave content={paragraph.content} />
                        {paragraph.pic && 
                            <img
                                src={`${baseApiUrl}/${paragraph.pic}`}
                                alt={mediaData.name}
                                className='mx-auto my-8'
                            />
                        }
                    </div>
                ))
            }

            
            {
                mediaData?.code && mediaData?.disclaimer &&
                <div className="caption">
                    {mediaData?.disclaimer}
                    <br />
                    <br />
                        {mediaData?.code}
                </div>
            }
        </div>
    );
};

export default BlogViewer;
