import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from '../../../../hooks/UseForm';
import { changePasswordStartLoading } from '../../../../actions/auth';

const initialForm = {
    email: '',
    password: '',
    re_password: ''
};

const ChangePasswordScreen = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { access } = useParams();

    const { changePasswordMessage, changePasswordLoading } = useSelector(state => state.ui);

    const [passwordMessage, setPasswordMessage] = useState('');
    const [formValues, handleInputChange, reset] = useForm(initialForm);
    const { 
        email,
        password,
        re_password
    } = formValues;

    const handleSubmit = (e) => {
        e.preventDefault();

        // * Verificar que las contraeñas coincidan
        if(password !== re_password) {
            setPasswordMessage('Las contraseñas no coinciden');
            return;
        }

        dispatch(changePasswordStartLoading({ email, password, access }));
        reset();
    };

    return (
        <div className="login-container">
            <div className="bg-blue">
                <div className="container" style={{ width: "100%", position: "relative", height: "100%" }}>
                    <header className="header">
                        <img
                            src="/images/logo.png"
                            alt="Sospechar no falla logo"
                            className="logo cursor-pointer"
                            onClick={() => navigate('/para-medicos/iniciar-sesion')}
                        />
                        <div className="col-md-6 d-flex align-items-center justify-content-end text-right m-0 p-0 logos-res">
                            <p className="m-0 mr-2 siguenos text-white">
                                Siguenos:
                            </p>
                            <a href="https://www.facebook.com/sospecharnofalla" target="_blank" rel="noreferrer">
                                <img
                                    src="/images/fece-b.png"
                                    className="img-fluid mr-2"
                                    alt="Facebook"
                                />
                            </a>
                            <a href="https://www.instagram.com/sospechar_nofalla/" target="_blank" rel="noreferrer">
                                <img
                                    src="/images/insta-b.png"
                                    className="img-fluid mr-2"
                                    alt="Instagram"
                                />
                            </a>
                            <a href="https://www.instagram.com/sospechar_nofalla/" target="_blank" rel="noreferrer">
                                <img
                                    src="/images/tik-b.png"
                                    className="img-fluid mr-2"
                                    alt="Tik tok"
                                />
                            </a>
                        </div>
                    </header>
                    {/** cartas */}
                    <div className="content ">
                        <div className="fila">
                            <div className="col-xl-8">
                                <div
                                    className="class-cartas"
                                >
                                    <div
                                        className="card card-ingrsa card-aqui h-auto"
                                        style={{ width: 330}}
                                    >
                                        <div className="card-body">
                                            <h5 className="card-title title-form1">
                                                Cambiar contraseña
                                            </h5>

                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <label
                                                        className="text-label"
                                                        htmlFor="exampleInputEmail1"
                                                    >
                                                        Correo electronico*
                                                    </label>
                                                    <input
                                                        type="email"
                                                        name='email'
                                                        value={email}
                                                        onChange={handleInputChange}
                                                        className="form-control border border-primary"
                                                        id="exampleInputEmail1"
                                                        aria-describedby="emailHelp"
                                                        autoComplete='off'
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="exampleInputPassword1"
                                                        className="text-label"
                                                    >
                                                        Nueva contraseña*
                                                    </label>
                                                    <input
                                                        type="password"
                                                        name='password'
                                                        value={password}
                                                        onChange={handleInputChange}
                                                        className="form-control border border-primary"
                                                        id="exampleInputPassword1"
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="exampleInputPassword1"
                                                        className="text-label"
                                                    >
                                                        Repetir contraseña*
                                                    </label>
                                                    <input
                                                        type="password"
                                                        name='re_password'
                                                        value={re_password}
                                                        onChange={handleInputChange}
                                                        className="form-control border border-primary"
                                                        id="exampleInputPassword2"
                                                        required
                                                    />
                                                </div>

                                                {
                                                    changePasswordMessage &&
                                                    <p
                                                        className={
                                                                changePasswordMessage.type === 'error'
                                                                ? "error-message"
                                                                : "success-message"
                                                        }
                                                    >
                                                            {changePasswordMessage.message}*
                                                    </p>
                                                }

                                                {
                                                    passwordMessage && 
                                                    <p className="error-message">
                                                        {passwordMessage}*
                                                    </p>
                                                }

                                                <button
                                                    type="submit"
                                                    className={
                                                        changePasswordLoading
                                                            ? "btn btn-primary mt-3 disabled"
                                                            : "btn btn-primary mt-3 entrar"
                                                    }
                                                    style={{ width: 'fit-content' }}
                                                >
                                                    {changePasswordLoading
                                                        ? 'Actualizando...'
                                                        :
                                                        <>
                                                            Guardar cambios
                                                            <img
                                                                src="/images/flecha-white2.png"
                                                                className="d-inline-block"
                                                                alt="flecha logo"
                                                                style={{ marginLeft: 10 }}
                                                            />
                                                        </>
                                                    }
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePasswordScreen;