import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { baseApiUrl } from "../../../../helpers/global";
import { lastMediasStartLoading, lastSocialMediasStartLoading } from '../../../../actions/medias';
import Menu from "../../../layouts/header/menu/Menu";
import MediaCard from "../../../layouts/cards/MediaCard";
import BannerCard from "../../../layouts/cards/BannerCard";
import Loader from "../../../layouts/loader/Loader";
import Banner from "../../../layouts/banner/Banner";
import MainText from "../../../layouts/mainText/MainText";

import "./Home.styles.scss";

const Principal = () => {
    
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const currentSection = pathname.split('/')[1];

    const { lastMediasLoading, lastSocialMediasLoading } = useSelector(state => state.ui);
    const { lastMedias, lastSocialMedias } = useSelector(state => state.medias);
    
    const [width, setWidth] = useState(window.innerWidth);

    // ? Obtener las 3 ultimas medias de la base de datos
    const getLastMedias = useCallback(() => {
        if (currentSection === 'para-todos') dispatch(lastMediasStartLoading({ professional: false }));
        if (currentSection === 'para-medicos') dispatch(lastMediasStartLoading({ professional: true }));
    }, [dispatch, currentSection]);

    // ? Obtener las ultimas 3 medias de redes sociales 
    const getLastSocialMedias = useCallback(() => {
        if (lastSocialMedias.length === 0) dispatch(lastSocialMediasStartLoading());
    }, [dispatch, lastSocialMedias.length]);

    useEffect(() => {
        getLastMedias();
        getLastSocialMedias();
        window.addEventListener("resize", () => setWidth(window.innerWidth));
        window.scroll({ top: 0 });
    }, [getLastMedias, getLastSocialMedias]);

    return (
        <>
            <Menu />
            
            {/* BANNER */}
            <Banner 
                title={
                    currentSection === 'para-todos'
                        ? 'Impacto de la falla cardíaca mundialmente'
                        : 'Cardiopatías genéticas. Ejemplos de factores implicados'
                }
                text={
                    currentSection === 'para-todos'
                        ? 'Dale un vistazo a las cifras sobre el impacto de la falla cardíaca, la falla renal y la diabetes mundial y localmente.'
                        : <span>Cardiopatías genéticas. <br/> Ejemplos de factores implicados</span>
                }
                btnText={
                    currentSection === 'para-todos' ? 'Ver aquí' : 'Leer más'
                }
                bc={
                    currentSection === 'para-todos' ? 'bg-banner-pacientes-image' : 'bg-banner-medicos-image'
                }
            />

            {/* FIRST CARDS */}
            <div>
                <div className="container" style={{ marginTop: "-30px" }}>
                    <div className="row fadeIn">
                        <BannerCard
                            thumb={
                                currentSection === 'para-todos'
                                    ? '/images/snf-mujer-ejercicio.png'
                                    : '/images/corazon-en-mano.png'
                            }
                            alt={
                                currentSection === 'para-todos'
                                    ? 'Concepto sospechar no falla'
                                    : 'Consenso sobre la definición de falla cardíaca'
                            }
                            title={
                                currentSection === 'para-todos'
                                    ? 'Concepto sospechar no falla. Investigadores encuentran un nuevo sospechoso de ocasionar...'
                                    : '¿Por qué es vital llegar a un consenso sobre la definición de la falla cardíaca?'
                            }
                        />
                        <BannerCard
                            thumb={
                                currentSection === 'para-todos'
                                    ? '/images/snf-rinones.png'
                                    : '/images/atomo.png'
                            }
                            alt={
                                currentSection === 'para-todos'
                                    ? 'Etapas de la enfermedad renal crónica'
                                    : 'Definición universal de la falla cardíaca'
                            }
                            title={
                                currentSection === 'para-todos'
                                    ? 'Etapas de la enfermedad renal crónica (breve descripción del nivel de daño que se presenta)...'
                                    : 'Definición universal de la falla cardíaca según diversas asociaciones de cardiología.'
                            }
                        />
                    </div>
                </div>
            </div>


            {/* MAIN TEXT */}
            <MainText 
                width={width}
                firstText={
                    currentSection === 'para-todos'
                        ? 'En este sitio web encontrarás información actualizada sobre como prevenir, sospechar y vivir con enfermedades como la falla cardiaca, enfermedad renal crónica y diabetes.'
                        : 'En este sitio web encontrarás información actualizada y soportada con diversas fuentes sobre como prevenir, sospechar, diagnosticar y darle un manejo adecuado a enfermedades como la falla cardiaca, enfermedad renal crónica y diabetes.'
                }
                firstPic={
                    currentSection === 'para-todos'
                        ? '/images/snf-corazon-holograma.png'
                        : '/images/medicos-cirugia.png'
                }
                secondPic={
                    currentSection === 'para-todos'
                        ? '/images/snf-medicos-corazon.png'
                        : '/images/medicos-tecnologia.png'
                }
            />
            
            {/* LAST MEDIAS */}
            <section className="new-content">
                <div className="container">
                    <h2>Contenidos nuevos</h2>
                    <p>Mantente actualizado con nuestros nuevos contenidos.</p>
                    <div className="row">
                        {   
                            lastMediasLoading 
                                ? <Loader />
                                : lastMedias?.map((media, index) => (
                                    <MediaCard 
                                        key={media.id}
                                        thumb={`${baseApiUrl}/${media.thumbnail}`}
                                        name={media.type}
                                        description={media.description}
                                        url={
                                            media.url 
                                                ? media.url
                                                : `${media.section_slug}/${media.type_slug}/${media.media_slug}`
                                        }
                                        external={!!media.url}
                                    />
                                ))
                        }
                    </div>
                </div>
            </section>

            <section className="new-content">
                <div className="container">
                    <h2>Nuevos contenidos en redes sociales</h2>
                    <p>
                        Accede al contenido interactivo que preparamos en
                        Instagram, Facebook y TikTok para ti y siguenos para
                        tener a la mano la información que necesitas.{" "}
                    </p>
                    <div className="row">
                        {   
                        lastSocialMediasLoading
                            ? <Loader />
                            : lastSocialMedias?.map((media, key) => (
                                <MediaCard
                                key={key}
                                    thumb={`${baseApiUrl}/${media.thumbnail}`}
                                    name={media.name}
                                    description={media.description}
                                    external={true}
                                    url={media.url}
                                />
                            ))
                        }
                    </div>
                </div>
            </section>
        </>
    );
};

export default Principal;
