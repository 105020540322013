import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserPictureStartLoading } from '../../../actions/auth';
import { baseApiUrl } from '../../../helpers/global';
import { uiClosePicModal } from '../../../actions/ui';
import Loader from '../loader/Loader';

const EditPicModal = () => {

    const dispatch = useDispatch();

    const { userData: { pic } } = useSelector(state => state.auth);
    const { updateUserLoading } = useSelector(state => state.ui);
    
    const [ file, setFile ] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(updateUserPictureStartLoading({ file }));
    };

    const handleClose = () => {
        document.getElementsByTagName('html')[0].style.overflowY = 'initial';
        dispatch(uiClosePicModal());
    };

    useEffect(() => {
        document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
    }, []);

    return (
        <div className='edit_user-modal'>
            <form onSubmit={handleSubmit}>
                <h4>Cambiar foto</h4>

                <div className='edit-pic-img'>
                    <img src={`${baseApiUrl}/${pic}`} alt='Foto de perfil de usuario' width={200} />
                </div>

                <div className="form-group">
                    <input 
                        type='file' 
                        onChange={(e) => setFile(e.target.files[0])}
                        required 
                    />
                </div>
                <div className='modal-btns'>
                    {
                        updateUserLoading
                            ? <Loader />
                            :
                            <>
                                <button
                                    className='cancel'
                                    type='button'
                                    onClick={handleClose}
                                >
                                    Cancelar
                                </button>
                                <button
                                    className='submit'
                                    type='submit'
                                >
                                    Actualizar foto
                                </button>
                            </>
                    }
                </div>
            </form>
        </div>
    );
};

export default EditPicModal;