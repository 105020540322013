import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { fadeIn } from '../../../helpers/variants';

const MainText = ({ firstText, firstPic, secondPic, width }) => {
    return (
        <section className="sections">
            <div className="container">
                <div className="row">
                    <motion.div 
                        variants={fadeIn('right', 0.1)}
                        initial='hidden'
                        whileInView={'show'}
                        viewport={{ once: false, amount: 0.3 }}
                        className="col-sm-6 text-description"
                    >
                        <h2 className="title">Sobre Sospechar</h2>
                        <p>
                            {firstText}
                        </p>
                    </motion.div>
                    <motion.div 
                        variants={fadeIn('left', 0.1)}
                        initial='hidden'
                        whileInView={'show'}
                        viewport={{ once: false, amount: 0.3 }}
                        className="col-sm-6"
                    >
                        <img src={firstPic} alt="Cardiología" />
                    </motion.div>
                </div>
                <div className="row mt-[30px]">
                    {width > 575 ? (
                        <>
                            <motion.div 
                                variants={fadeIn('right', 0.1)}
                                initial='hidden'
                                whileInView={'show'}
                                viewport={{ once: false, amount: 0.3 }}
                                className="col-sm-6"
                            >
                                <img src={secondPic} alt="Cardiología" />
                            </motion.div>
                            <motion.div 
                                variants={fadeIn('left', 0.1)}
                                initial='hidden'
                                whileInView={'show'}
                                viewport={{ once: false, amount: 0.3 }}
                                className="col-sm-6 text-description"
                            >
                                <h2 className="title">¿Qué es?</h2>
                                <p>
                                    Sospechar No Falla es una iniciativa que busca impactar de forma positiva en la población general y en los pacientes que padezcan enfermedades como falla cardíaca, enfermedad renal crónica o diabetes. A través de materiales interactivos como blogs, infografías, videos y podcast, estarás al tanto de información y recomendaciones relacionadas con estas enfermedades que podrás aplicar diariamente para mejorar tu calidad de vida y la de los que te rodean.
                                </p>
                            </motion.div>
                        </>
                    ) : (
                        <>
                            <div className="col-sm-6 text-description">
                                    <h2 className="title">¿Qué es?</h2>
                                <p>
                                        Sospechar No Falla es una iniciativa que busca impactar de forma positiva en la población general y en los pacientes que padezcan enfermedades como falla cardíaca, enfermedad renal crónica o diabetes. A través de materiales interactivos como blogs, infografías, videos y podcast, estarás al tanto de información y recomendaciones relacionadas con estas enfermedades que podrás aplicar diariamente para mejorar tu calidad de vida y la de los que te rodean.
                                </p>
                            </div>
                            <motion.div 
                                variants={fadeIn('right', 0.1)}
                                initial='hidden'
                                whileInView={'show'}
                                viewport={{ once: false, amount: 0.3 }}
                                className="col-sm-6"
                            >
                                    <img src={secondPic} alt="Cardiología" />
                            </motion.div>
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};

MainText.propTypes = {
    firstText: PropTypes.string.isRequired,
    firstPic: PropTypes.string.isRequired,
    secondPic: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired
};

export default MainText;