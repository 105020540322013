import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { fadeIn } from '../../../helpers/variants';

import './sectionHeader.styles.scss';

const SectionHeader = ({ customClass, loading, sectionName, sectionDescription }) => {
    return (
        <div className={customClass}
        >
            {
                !loading &&
                <div className="container">
                    <motion.h1
                        variants={fadeIn('right', 0.1)}
                        initial='hidden'
                        whileInView={'show'}
                        viewport={{ once: false, amount: 0.3 }}
                    >
                        {sectionName}
                    </motion.h1>
                    <motion.h2
                        variants={fadeIn('right', 0.3)}
                        initial='hidden'
                        whileInView={'show'}
                        viewport={{ once: false, amount: 0.3 }}
                    >
                        {sectionDescription}
                    </motion.h2>
                </div>
            }
        </div>
    );
};

SectionHeader.propTypes = {
    customClass: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    sectionName: PropTypes.string.isRequired,
    sectionDescription: PropTypes.string.isRequired,
};

export default SectionHeader;