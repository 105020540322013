import Swal from 'sweetalert2';

export const handleCopyToClipboard = (text) => {
    let aux = document.createElement('input');
    aux.setAttribute('value', text);

    document.body.appendChild(aux);
    aux.select();
    document.execCommand('copy');

    Swal.fire({
        position: 'top-end',
        width: 'fit-content',
        icon: 'success',
        title: 'Copiado!',
        toast: true,
        showConfirmButton: false,
        timer: 1500
    });

    document.body.removeChild(aux);
}