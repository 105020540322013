import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// ? Rutas publicas, a las cuales se accede sin estar logueado (solo login)
const PublicRoutes = ({ children }) => {
    
    const { userData } = useSelector(state => state.auth);

    return !!userData
        ? <Navigate to='/para-medicos/sobre-sospechar-no-falla' />
        : children
};

export default PublicRoutes;