import React from "react";
import Toolbar from "../toolBar/Toolbar";
import { useLocation } from "react-router-dom";
import { baseApiUrl, baseFrontUrl } from "../../../helpers/global";
import { useSelector } from "react-redux";

const InfografiasViewer = () => {

  const { pathname } = useLocation();
  
  const { mediaData } = useSelector(state => state.medias);

  return (
    <>
      <div className="overflow-auto info-viewer">
        <img 
          className="min-w-full"
          src={`${baseApiUrl}/${mediaData?.file_path}`} 
          alt={mediaData?.name} 
        />
      </div>
      <Toolbar url={`${baseFrontUrl}${pathname}`} type="jpg" />
    </>
  );
};


export default InfografiasViewer;
