import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userStartLogOut } from '../../../../actions/auth';
import { baseApiUrl } from '../../../../helpers/global';
import { uiOpenPicModal, uiOpenUpdateModal } from '../../../../actions/ui';
import { useNavigate } from 'react-router-dom';

const ProfileMenu = () => {

        const dispatch = useDispatch();
        const navigate = useNavigate();

        const { userData } = useSelector(state => state.auth);

        return (
            <div
                className='user-profile-menu z-50'
                style={{
                    backgroundColor: "#d7d8da",
                }}
            >
                <div className="text-center">
                    <img
                        src={
                            userData?.pic
                                ? `${baseApiUrl}/${userData.pic}`
                                : "/images/icono-user.png"
                        }
                        width={60}
                        height={60}
                        className="d-inline-block align-top user-pic"
                        alt=""
                    />
                </div>
                <div
                    className="dropdown-item name-subitem"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                    }}
                >
                    <img
                        src="/images/icon-user.png"
                        alt=""
                        width={20}
                        height={20}
                        className="w-[20px] h-[20px]"
                    />
                    Hola, {userData?.name} {userData?.last_name}
                </div>
                <div
                    className="dropdown-item"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                    }}
                    onClick={() => dispatch(uiOpenPicModal())}
                >
                    <img
                        src="/images/camara-icon.png"
                        alt=""
                        width={20}
                        height={20}
                        className="w-[20px] h-[20px]"
                    />
                    Cambiar foto de perfil
                </div>
                <div
                    className="dropdown-item"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                    }}
                    onClick={() => dispatch(uiOpenUpdateModal())}
                >
                    <img
                        src="/images/reload-icon.png"
                        alt=""
                        width={20}
                        height={20}
                        className="w-[20px] h-[20px]"
                    />
                    Actualizar datos
                </div>
                <div
                    className="dropdown-item"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                    }}
                    onClick={() => navigate('/para-medicos/materiales-guardados')}
                >
                    <img
                        src="/images/icon-estrella.png"
                        alt=""
                        width={20}
                        height={20}
                        className="w-[20px] h-[20px]"
                    />
                    Favoritos
                </div>
                <div
                    className="dropdown-item"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                    }}
                    onClick={() => dispatch(userStartLogOut())}
                >
                    <img
                        src="/images/icon-cerrar.png"
                        alt=""
                        width={20}
                        height={20}
                        className="w-[20px] h-[20px]"
                    />
                    Cerrar sesión
                </div>
            </div>
        );
};

export default ProfileMenu;