import { types } from "../types/types";
import { freeFetch, tokenFetch } from "../helpers/fetch";
import Cookies from "universal-cookie";
import { 
    uiAuthFinishLoginLoading, 
    uiAuthFinishRegisterLoading, 
    uiAuthStartLoginLoading, 
    uiAuthStartRegisterLoading, 
    uiChangePasswordFinishtLoading, 
    uiChangePasswordStartLoading, 
    uiCloseUpdateModal, 
    uiUserDataUpdateFinishLoading,
    uiUserDataUpdateStartLoading
} from "./ui";
import Swal from "sweetalert2";

const cookies = new Cookies();

// ? Login de usuario
export const userStartLogin = ({ email, password, remember }) => {
    return async (dispatch) => {
        try {
            dispatch(uiAuthStartLoginLoading());

            const resp = await freeFetch('Usuarios/login', 'POST', { email, password });
            const data = await resp.json();

            if(data.status) {
                // * Guardar token en localstorage
                localStorage.setItem('token', data.token);
                dispatch(userLogged(data.user_data));

                // * Guardar credenciales en cookies si lo pide el usuario
                if(remember) {
                    cookies.set('email', email, { path: '/' });
                    cookies.set('pass', password, { path: '/' });
                } else {
                    cookies.remove('email', { path: '/' });
                    cookies.remove('pass', { path: '/' });
                }
            } else {

            }

            dispatch(uiAuthFinishLoginLoading(data.message));
        } catch(error) {
            console.log(error);
        }
    }
};

// ? Verificar valides del token de sesion
export const userStartCheckingToken = () => {
    return async (dispatch) => {
        try {
            const resp = await tokenFetch('Usuarios/verifyToken');
            const data = await resp.json();

            if(data.status) {
                // * Guardar token en localstorage
                localStorage.setItem('token', data.token);
                dispatch(userLogged(data.user_data));
            } else {
                dispatch(userStartLogOut());
            }
        } catch(error) {
            console.log(error);
        }
    }
};

const userLogged = (userData) => ({
    type: types.authLogged,
    payload: userData
});

// ? Registro de nuevo usuario
export const userStartRegister = ({ userData }) => {
    return async (dispatch) => {
        try {
            dispatch(uiAuthStartRegisterLoading());

            const resp = await freeFetch('Usuarios/register', 'POST', userData);
            const data = await resp.json();

            if(data.status) {
                dispatch(uiAuthFinishRegisterLoading({
                    message: data.message,
                    type: 'success'
                }));
            } else {
                dispatch(uiAuthFinishRegisterLoading({
                    message: data.message,
                    type: 'error'
                }));
            }

        } catch(error) {
            console.log(error);
        }
    }
}

// ? Actualizar datos de usuario
export const updateUserStartLoading = ({ newData }) => {
    return async (dispatch) => {
        try {
            dispatch(uiUserDataUpdateStartLoading());

            const resp = await tokenFetch('Usuarios/updateData', 'POST', newData);
            const data = await resp.json();

            let icon;

            if(data.status) {
                localStorage.setItem('token', data.token);
                dispatch(updateUserLoaded(newData));

                icon = 'success';
            } else {
                icon = 'error';
            }

            Swal.fire({
                position: 'top-end',
                width: 'fit-content',
                icon,
                title: data.message,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });

            dispatch(uiCloseUpdateModal());
            dispatch(uiUserDataUpdateFinishLoading(data.message));

            document.getElementsByTagName('html')[0].style.overflowY = 'initial';
        } catch(error) {
            console.log(error);
        }
    }
};

// ? Cambiar foto de perfil de usuario
export const updateUserPictureStartLoading = ({ file }) => {
    return async (dispatch) => {
        try {
            dispatch(uiUserDataUpdateStartLoading());

            const resp = await tokenFetch('Usuarios/uploadProfilePic', 'POST', { profile_pic: file });
            const data = await resp.json();

            let icon;

            if(data.status) {
                localStorage.setItem('token', data.token);
                dispatch(updateUserLoaded({ pic: data.new_pic }));

                icon = 'success';
            } else {
                icon = 'error';
            }

            Swal.fire({
                position: 'top-end',
                width: 'fit-content',
                icon,
                title: data.message,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });

            dispatch(uiUserDataUpdateFinishLoading(data.message));
            document.getElementsByTagName('html')[0].style.overflowY = 'initial';
        } catch(error) {
            console.log(error);
        }
    }
};

const updateUserLoaded = (newData) => ({
    type: types.userDataUpdated,
    payload: newData
});

// ? Deslogueo de usuario
export const userStartLogOut = () => {
    return (dispatch) => {
        localStorage.clear();
        dispatch(userLogOut());
    }
};

const userLogOut = () => ({
    type: types.authLogOut
});

// ? Cambiar contraseña de usuario
export const changePasswordStartLoading = ({ email, password, access }) => {
    return async (dispatch) => {
        try {
            dispatch(uiChangePasswordStartLoading());

            const resp = await tokenFetch('Usuarios/changePassword', 'POST', { email, password, access });
            const data = await resp.json();

            if(data.status) {
                dispatch(uiChangePasswordFinishtLoading({
                    message: data.message,
                    type: 'success'
                }));
            } else {
                dispatch(uiChangePasswordFinishtLoading({
                    message: data.message,
                    type: 'error'
                }));
            }

        } catch(error) {
            console.log(error);
        }
    }
};