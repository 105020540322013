import { types } from '../types/types';

const initialState = {
    loaded: false,
    userData: null
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.authLogged:
            return {
                userData: action.payload,
                loaded: true
            };

        case types.authLogOut:
            return {
                loaded: true,
                userData: null
            };

        case types.userDataUpdated:
            return {
                ...state,
                userData: { ...state.userData, ...action.payload }
            };

        default:
            return state;
    }
};
