import { createStore, applyMiddleware, compose } from 'redux';
import { rootReducer } from '../reducers/rootReducer';
import thunk from 'redux-thunk';

const composerEnhancers = 
    (typeof window !== 'undefined' && 
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;


export const store = createStore(
    rootReducer,
    composerEnhancers(applyMiddleware(thunk))
);