import React from 'react';
import Toolbar from '../toolBar/Toolbar';
import { useLocation } from 'react-router-dom';
import { baseApiUrl, baseFrontUrl } from '../../../helpers/global';
import { useSelector } from 'react-redux';

const VideoPlayer = () => {

    const { pathname } = useLocation();

    const { mediaData } = useSelector(state => state.medias);

    return (
        <>
            <video 
                width="100%" 
                height="auto" 
                controlsList="nodownload"
                controls
            >
                <source src={`${baseApiUrl}/${mediaData?.file_path}`} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <Toolbar
                url={`${baseFrontUrl}${pathname}`}
                type='mp4'
            />
        </>
    );
};

export default VideoPlayer;