import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../../hooks/UseForm';
import { updateUserStartLoading } from '../../../actions/auth';
import { uiCloseUpdateModal } from '../../../actions/ui';
import Loader from '../loader/Loader';

const EditUserModal = () => {

    const dispatch = useDispatch();

    const { userData } = useSelector(state => state.auth);
    const { updateUserLoading } = useSelector(state => state.ui);

    const [formValues, handleInputChange] = useForm({
        name: userData?.name,
        last_name: userData?.last_name,
        institution: userData?.institution,
        phone: userData?.phone,
    });

    const { 
        name,
        last_name,
        institution,
        phone,
    } = formValues;

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(updateUserStartLoading({ newData: formValues }));
    };

    const handleClose = () => {
        document.getElementsByTagName('html')[0].style.overflowY = 'initial';
        dispatch(uiCloseUpdateModal());
    };

    useEffect(() => {
        document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
    }, []);

    return (
        <div className='edit_user-modal'>
            <form onSubmit={handleSubmit}>
                <h4>Editar datos</h4>

                <div className="form-group">
                    <label
                        htmlFor="exampleInputPassword1"
                        className="text-label"
                    >
                        Nombres*
                    </label>
                    <input 
                        className='form-control border border-primary'
                        type='text' 
                        name='name'
                        value={name}
                        onChange={handleInputChange}
                        autoComplete='off'
                        required 
                    />
                </div>
                <div className="form-group">
                    <label
                        htmlFor="exampleInputPassword1"
                        className="text-label"
                    >
                        Apellidos*
                    </label>
                    <input 
                        className='form-control border border-primary'
                        type='text' 
                        name='last_name'
                        value={last_name}
                        onChange={handleInputChange}
                        autoComplete='off'
                        required 
                    />
                </div>
                <div className="form-group">
                    <label
                        htmlFor="exampleInputPassword1"
                        className="text-label"
                    >
                        Institución donde labora*
                    </label>
                    <input 
                        className='form-control border border-primary'
                        type='text' 
                        name='institution'
                        value={institution}
                        onChange={handleInputChange}
                        autoComplete='off'
                        required 
                    />
                </div>
                <div className="form-group">
                    <label
                        htmlFor="exampleInputPassword1"
                        className="text-label"
                    >
                        Teléfono*
                    </label>
                    <input 
                        className='form-control border border-primary'
                        type='text' 
                        name='phone'
                        value={phone}
                        onChange={handleInputChange}
                        autoComplete='off'
                        required 
                    />
                </div>

                <div className='modal-btns'>
                    {
                        updateUserLoading
                            ? <Loader />
                            : 
                            <>
                                <button 
                                    className='cancel' 
                                    type='button'
                                    onClick={handleClose}
                                >
                                    Cancelar
                                </button>
                                <button
                                    className='submit'
                                    type='submit'
                                >
                                    Guardar cambios
                                </button>
                            </>
                    }
                </div>
            </form>
        </div>
    );
};

export default EditUserModal;