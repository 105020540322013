import { baseApiUrl } from "./global";

export const freeFetch = (endpoint, method = 'GET', data = {}) => {
    const url = `${baseApiUrl}/${endpoint}`;

    if(method === 'GET') {
        return fetch(url);
    } else {
        const body = new FormData();

        for(const [name, value] of Object.entries(data)){
            body.append(name, value);
        };

        return fetch(url, { method, body });
    }
};

export const tokenFetch = (endpoint, method = 'GET', data = {}) => {
    const url = `${baseApiUrl}/${endpoint}`;
    const token = localStorage.getItem('token') || '';

    let headers = { 'X-Token': token };

    if(method === 'GET') {
        return fetch(url, {
            method,
            headers
        });
    } else {
        const body = new FormData();

        for(const [name, value] of Object.entries(data)){
            body.append(name, value);
        };

        return fetch(url, { method, headers, body });
    }
};